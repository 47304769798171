import { Injectable } from '@angular/core';
import { minify } from '@shared/services/utils-helper.service';
import { ThemingService } from '@core/services/theming/theming.service';

@Injectable({
  providedIn: 'root'
})
export class JiraWidgetService {
  private refreshIntervalId;
  private primaryColor: string;

  constructor(private _themingService: ThemingService) {
    this.primaryColor = this._themingService.primary;
    this.appendCssToJiraSupportWidget();
  }

  appendCssToJiraSupportWidget() {
    const styles = document.createElement('style');
    styles.textContent = minify(`
    .ak-button__appearance-primary, #help-button, #widget-header {
         background-color: ${this.primaryColor} !important; 
    } 
    .search-body {
        background-color: ${this.primaryColor} !important;
    }
    .help-button{
      box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    }
    .ak-field-text:focus{
      border-color: whitesmoke !important;
    }
    `);
    this.refreshIntervalId = setInterval(() => this.appendStyleToIframe(styles), 100);
  }

  appendStyleToIframe(styles) {
    const iframe = document.querySelector('#jsd-widget') as HTMLIFrameElement;
    if (!!iframe) {
      iframe.style.zIndex = '999';
      iframe.contentWindow.document.body.appendChild(styles);
      clearInterval(this.refreshIntervalId);
    }
  }
}
