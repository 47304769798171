import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { AnonymousAccessService } from '@campaign/services/anonymous-access.service';
import { AppState } from '@app/store/app.state';
import { AuthenticationService } from '@auth/services/authentication.service';
import { LoginService } from '@auth/services/login.service';
import { Store } from '@ngxs/store';
import { REFRESH_TOKEN_URL, SET_PASSWORD_URL } from '@shared/config/endpoints';
import { throwError } from 'rxjs';
import { catchError, filter, switchMap } from 'rxjs';
import { SetAccessToken } from '@auth/store/auth.actions';
import { cloneRequestWithAccessToken } from '@app/shared/services/utils-helper.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  constructor(
    private authenticationService: AuthenticationService,
    private anonymousService: AnonymousAccessService,
    private loginService: LoginService,
    private router: Router,
    private store: Store,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    const isAnonymousUser = this.anonymousService.isAnonymousUser();
    const authToken = isAnonymousUser
      ? this.anonymousService.getAnonymousAccessToken()
      : this.authenticationService.getAccessToken();
    const clonedReq = cloneRequestWithAccessToken(request, authToken);
    return next.handle(clonedReq).pipe(
      catchError((err) => {
        if (err.status === 401) {
          if (!isAnonymousUser) {
            if (clonedReq.url.includes(REFRESH_TOKEN_URL)) {
              this.logout();
              return throwError(() => err);
            }
            if (clonedReq.url.includes(SET_PASSWORD_URL)) {
              return throwError(() => err);
            }
            return this.RefreshAccessTokenAndResend(request, next);
          } else {
            this.router.navigate(['/404']);
          }
        }
        return throwError(() => err);
      }),
    );
  }

  private RefreshAccessTokenAndResend(request: HttpRequest<any>, next: HttpHandler) {
    return !this.isRefreshing
      ? this.resendIfNotRefreshingYet(request, next)
      : this.resendIfAlreadyRefreshing(request, next);
  }

  private resendIfNotRefreshingYet(request: HttpRequest<any>, next: HttpHandler) {
    this.isRefreshing = true;
    this.store.dispatch(new SetAccessToken(null));
    return this.authenticationService.refreshAccessToken().pipe(
      switchMap((accessToken) => {
        this.isRefreshing = false;
        const actingFor = this.store.selectSnapshot<string>(AppState.actingFor);
        return next.handle(cloneRequestWithAccessToken(request, accessToken, actingFor));
      }),
    );
  }

  private resendIfAlreadyRefreshing(request: HttpRequest<any>, next: HttpHandler) {
    return this.authenticationService.accessToken$.pipe(
      filter((_) => !!_),
      switchMap((accessToken) => {
        const actingFor = this.store.selectSnapshot<string>(AppState.actingFor);
        return next.handle(cloneRequestWithAccessToken(request, accessToken, actingFor));
      }),
    );
  }

  private logout() {
    this.loginService.logout();
    this.router.navigate(['/login']);
  }
}
