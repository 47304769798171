import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { AppState } from '@app/store/app.state';
import { LanguageService } from '@core/services/language/language.service';
import { TranslateService } from '@ngx-translate/core';
import { Select, Store } from '@ngxs/store';
import { GoogleAnalyticsService } from '@shared/services/google-analytics.service';
import { TermsAndConditionsService } from '@shared/services/terms-and-conditions.service';
import { Observable, Subject, filter, interval, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SetUpdateAvailable } from './store/app.actions';
import { AuthState } from './features/auth/store/auth.state';

declare let gtag: Function;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  public closed$ = new Subject<void>();
  public environment = environment;
  version$: Observable<string> = inject(Store).select(AppState.version);
  updateAvailable$: Observable<boolean> = inject(Store).select(AppState.updateAvailable);
  private destroyRef = inject(DestroyRef);

  constructor(
    _googleAnalyticsService: GoogleAnalyticsService,
    private _router: Router,
    private _swUpdate: SwUpdate,
    public _tcService: TermsAndConditionsService,
    private titleService: Title,
    private _route: ActivatedRoute,
    private _translateService: TranslateService,
    private _languageService: LanguageService,
    private store: Store,
    private _cd: ChangeDetectorRef,
  ) {
    this.checkForUpdate();
    this._router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntilDestroyed(),
        switchMap((event: NavigationEnd) => {
          if (event.url.includes('login')) {
            // Update app automatically if on login page
            const updateAvailable = this.store.selectSnapshot(AppState.updateAvailable);
            !!updateAvailable && this.activateUpdate();
          }

          gtag('event', 'page_view', {
            page_title: event.urlAfterRedirects,
            page_location: event.urlAfterRedirects,
            page_path: event.urlAfterRedirects,
            send_to: 'G-E1Z7B5ZHVV',
          });
          return this.store.select<string>(AppState.appName).pipe(takeUntilDestroyed(this.destroyRef));
        }),
        filter((_) => !!_),
      )
      .subscribe((appName) => {
        this.updateTitle(appName);
      });
  }

  updateTitle(appName) {
    const titleKey = this._route.root.firstChild.snapshot.data['titleKey'];
    this.titleService.setTitle(`${appName} ${!!titleKey ? '| ' + this._translateService.instant(titleKey) : ''}`);
    this._cd.markForCheck();
  }

  ngOnInit() {
    this._languageService.init();

    !!environment.production &&
      interval(60 * 1e3)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((_) => this.checkForUpdate());
  }

  activateUpdate() {
    this.store.dispatch(new SetUpdateAvailable(false));
    this._swUpdate.activateUpdate().then((activated) => {
      if (activated) {
        const localStorageKeys = [];
        for (let i = 0; i < localStorage.length; i++) {
          if (!['auth', 'app', 'sales', 'tenant'].includes(localStorage.key(i))) {
            localStorageKeys.push(localStorage.key(i));
          }
        }
        localStorageKeys.forEach((key) => {
          localStorage.removeItem(key);
        });
        const auth = this.store.selectSnapshot(AuthState as any);
        this.store.reset({
          auth,
          app: { appOnline: true, adblockPresent: false, updateAvailable: false },
        });

        location.reload();
        this._cd.markForCheck();
      }
    });
  }

  /* SW Update */
  checkForUpdate() {
    if (this._swUpdate && this._swUpdate.isEnabled) {
      this._swUpdate.checkForUpdate().then((available) => {
        const updateAlreadyAvailable = this.store.selectSnapshot(AppState.updateAvailable);
        if (available && !updateAlreadyAvailable) {
          this.store.dispatch(new SetUpdateAvailable(true));
        }
      });
    }
  }
}
