import { Injectable, inject } from '@angular/core';
import { AddTermsAndConditions } from '@app/store/app.actions';
import { AppState } from '@app/store/app.state';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TermsAndConditionsService {
  termsAndConditionsAccepted$: Observable<boolean> = inject(Store).select(AppState.termsAndConditionsAccepted);

  private store = inject(Store);

  public accept() {
    this.store.dispatch(new AddTermsAndConditions(new Date().toUTCString()));
  }
}
