import { Injectable } from "@angular/core";
import { isRefreshNeeded } from "@app/shared/services/utils-helper.service";
import { NgxsNextPluginFn, NgxsPlugin } from "@ngxs/store";

@Injectable({
    providedIn: 'root',
})
export class StoreInterceptor implements NgxsPlugin {

    public handle(state, action, next: NgxsNextPluginFn): NgxsNextPluginFn {
        let refreshNeeded;
        if (!!action.refreshStore) {
            const { stateName, listAttribute, loadingAttribute, timestampAttribute } = action.refreshStore;
            refreshNeeded = isRefreshNeeded(state, action);
            // console.log('refreshNeeded',stateName, refreshNeeded);
            // console.log('refreshNeeded', new Date(), stateName, listAttribute, refreshNeeded);
            if (!!refreshNeeded?.timestamp) {
                const timestamp = !timestampAttribute ? `${listAttribute}Timestamp` : timestampAttribute;
                state = { ...state, [stateName]: { ...state[stateName], [listAttribute]: null, [timestamp]: refreshNeeded} };
                !!loadingAttribute && (state = { ...state, [stateName]: { ...state[stateName], [loadingAttribute]: false } });
                // console.log('state after refhresh:', state)
            }
        }
        return (!!refreshNeeded?.timestamp || !action.refreshStore) ? next(state, action) : next(state, { action, skip: true });
    }
}