import { MediaMatcher } from '@angular/cdk/layout';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  OnInit,
  ViewChild,
  inject
} from '@angular/core';
import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import { ActivatedRoute, NavigationEnd, Router, RouterLinkActive, RouterOutlet } from '@angular/router';
import { Language } from '@campaign/models/asset.model';
import { DEFAULT_LANGUAGE } from '@shared/config/config';
import { BusinessModule } from '@auth/models/auth.model';
import { LANGUAGES } from '@core/services/language/language.model';
import { LanguageService } from '@core/services/language/language.service';
import { ThemingService } from '@core/services/theming/theming.service';
import { UserProfileService } from '@core/services/user-profile/user-profile.service';
import { environment } from 'src/environments/environment';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslateModule } from '@ngx-translate/core';
import { BreadcrumbsComponent } from '../../../../shared/components/breadcrumbs/breadcrumbs.component';
import { MatListModule } from '@angular/material/list';
import { NgProgressModule } from 'ngx-progressbar';
import { MatMenuModule } from '@angular/material/menu';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';

@Component({
    selector: 'app-layout-anonymous',
    templateUrl: './layout-anonymous.component.html',
    styleUrls: ['./layout-anonymous.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [MatToolbarModule, MatButtonModule, MatIconModule, InlineSVGModule, MatMenuModule, NgProgressModule, MatSidenavModule, MatListModule, RouterLinkActive, BreadcrumbsComponent, RouterOutlet, TranslateModule]
})
export class AnonymousLayoutComponent implements OnInit {
  languages = LANGUAGES;
  language = DEFAULT_LANGUAGE;
  mobileQuery: MediaQueryList;
  environment = environment;

  public primaryColor: string;

  BusinessModule = BusinessModule;

  @ViewChild('sidenav') private sideNav: MatSidenav;
  destoryRef = inject(DestroyRef);
  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public userProfileService: UserProfileService,
    public languageService: LanguageService,
    private media: MediaMatcher,
    private _themingService: ThemingService,
    private _cdr: ChangeDetectorRef
  ) {
    this.primaryColor = this._themingService.primary;
    const displayLargeBreakpoint = this._themingService.getStyleVariable('breakpoint-display-large');
    this.mobileQuery = this.media.matchMedia(`(max-width: ${displayLargeBreakpoint})`);
    this._cdr.markForCheck();
  }

  setLanguage(language: Language): void {
    this.language = language;
    this.languageService.toggleLanguage(language);
    this._cdr.markForCheck();
  }

  ngOnInit() {
    this.router.events
      .pipe(takeUntilDestroyed(this.destoryRef))
      .subscribe(
        event =>
          !!this.mobileQuery.matches && this.sideNav.opened && event instanceof NavigationEnd && this.sideNav.close()
      );
  }
}
