@if (breadcrumbs?.length > 1) {
<section>
  @for (menuItem of breadcrumbs; track menuItem.url; let i = $index) {
  <a
    [routerLink]="[menuItem.url]"
    id="breadcrumbs_link_{{ i + 1 }}"
    routerLinkActive="router-link-active"
    [routerLinkActiveOptions]="{ exact: true }"
    [class.disabled]="!modules()"
  >
    <span>{{ menuItem.label | translate }}</span>
  </a>
  @if (i != breadcrumbs.length - 1) {
  <mat-icon>keyboard_arrow_right</mat-icon>
  } }
</section>
}
