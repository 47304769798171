import { ErrorHandler, Injectable } from '@angular/core';
import { SentryErrorHandler } from '@sentry/angular';

@Injectable()
export class GlobalErrorHandler extends SentryErrorHandler implements ErrorHandler {
  constructor() {
    // set readOnly field with ErrorHandlerOptions
    super({ showDialog: false });
  }

  handleError(error: any): void {
    // Chunk loading handler
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    chunkFailedMessage.test(error?.message) && window.location.reload();

    // SentryErrorHandler handlerError from here
    super.handleError(error);
  }
}
