import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from '@core/components/layout/layout.component';
// import { HoverPreloadStrategy } from 'ngx-hover-preload-v';
import { AnonymousLayoutComponent } from '@anonymous/components/layout/layout-anonymous.component';
import { NotFoundComponent } from './core/components/error-pages/404/not-found.component';
import { NotificationPageComponent } from './core/components/notification-page/notification-page.component';
import { AuthenticationGuard } from './features/auth/guards/authentication.guard';
import { AuthorizationGuard } from './features/auth/guards/authorization.guard';

const routes: Routes = [
  {
    path: 'anonymous',
    data: { titleKey: 'campaign.campaigns' },
    component: AnonymousLayoutComponent,
    loadChildren: () => import('@anonymous/anonymous.module').then((m) => m.AnonymousModule),
  },
  {
    path: '',
    component: LayoutComponent,
    data: { titleKey: 'home.title' },
    loadChildren: () => import('@home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'campaigns',
    component: LayoutComponent,
    data: { titleKey: 'campaign.campaigns' },
    children: [
      {
        path: '',
        loadChildren: () => import('@campaign/campaign.module').then((m) => m.CampaignModule),
      },
    ],
  },
  {
    path: 'guaranteed',
    component: LayoutComponent,
    data: { titleKey: 'guaranteed.guaranteedCampaigns' },
    children: [
      {
        path: '',
        loadChildren: () => import('@guaranteed-campaign/guaranteed-campaign.module').then(m => m.GuaranteedCampaignModule)
      }
    ]
  },
  {
    path: 'media-library',
    component: LayoutComponent,
    data: { titleKey: 'mediaLibrary.title' },
    loadChildren: () => import('@media-library/media-library.module').then((m) => m.MediaLibraryModule),
  },
  {
    path: 'publisher',
    component: LayoutComponent,
    data: { titleKey: 'site.siteManagementNavigation' },
    loadChildren: () => import('@site-management/site-management.module').then((m) => m.SiteManagementModule),
  },
  {
    path: 'users',
    component: LayoutComponent,
    data: { titleKey: 'user.userManagementNavigation' },
    loadChildren: () => import('@user-management/user-management.module').then((m) => m.UserManagementModule),
  },
  {
    path: 'tenants',
    component: LayoutComponent,
    data: { titleKey: 'tenant.title' },
    loadChildren: () => import('@tenant/tenant-management.module').then((m) => m.TenantManagementModule),
  },
  {
    path: 'sales-channel',
    component: LayoutComponent,
    data: { titleKey: 'salesChannel.title' },
    loadChildren: () => import('@sales-channel/sales-channel.module').then((m) => m.SalesChannelModule),
  },
  {
    path: 'notifications',
    component: LayoutComponent,
    canActivate: [AuthenticationGuard],
    data: { titleKey: 'notification.title' },
    children: [
      {
        path: '',
        component: NotificationPageComponent,
      },
    ],
  },
  {
    path: 'login',
    loadChildren: () => import('@auth/auth.module').then((m) => m.AuthModule),
  },
  { path: '404', component: NotFoundComponent },
  {
    path: '**',
    redirectTo: '/',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // preloadingStrategy: HoverPreloadStrategy,
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
