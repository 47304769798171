<router-outlet/>

@if(!!(updateAvailable$ | async)) {
<app-update (updateApplication)="activateUpdate()"/>
}

<app-offline-banner/>
<!-- <ng-container *ngIf="!(_tcService.termsAndConditionsAccepted$ | async) && !loginPage">
  <app-info-banner>
    <span>
      {{ 'termsAndConditionsPrefix' | translate }}
      <a target="_blank" href="https://www.adtrac.media/wp-content/uploads/2020/05/AGB-Adtrac-20200428.pdf">
        {{ 'termsAndConditions' | translate }}</a
      >
    </span>
    <button mat-flat-button color="primary" (click)="_tcService.accept()" id="app_btn_continue">
      <span translate>button.continue</span>
    </button>
  </app-info-banner>
</ng-container> -->

<!-- <ng-container *ngIf="(updateAvailable$ | async) && !updateLater">
  <app-info-banner>
    <span>{{ 'newVersion' | translate }}</span>
    <div class="flex">
      <button mat-flat-button mat-stroked-button class="mr-3" (click)="updateLater = true" id="app_btn_update_later">
        <mat-icon>close</mat-icon> {{ 'updateLater' | translate }}
      </button>
      <button
        mat-flat-button
        color="primary"
        class="update-button"
        (click)="activateUpdate()"
        id="app_btn_system_update"
      >
        <mat-icon>system_update_alt</mat-icon> <span> {{ 'update' | translate }}</span>
      </button>
    </div>
  </app-info-banner>
</ng-container> -->
