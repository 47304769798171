<button
  mat-icon-button
  (click)="isOpen = !isOpen"
  type="button"
  cdkOverlayOrigin
  #trigger="cdkOverlayOrigin"
  id="lyt_btn_notifications"
  [matTooltip]="'notification.title' | translate"
  matTooltipPosition="below"
>
  <mat-icon
    matBadgeColor="warn"
    id="notification_unread_count"
    matBadgeOverlap="true"
    [matBadgeHidden]="!(numberOfUnreadNotifications$ | async)"
    aria-hidden="false"
    [matBadge]="(numberOfUnreadNotifications$ | async) < 99 ? (numberOfUnreadNotifications$ | async) : '99+'"
    >notifications</mat-icon
  >
</button>
<ng-template
  cdkConnectedOverlay
  cdkConnectedOverlayPush
  [cdkConnectedOverlayPositions]="!!mobileQuery.matches ? positionPairsSmallerScreen : positionPairs"
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isOpen"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
  (backdropClick)="closeOverlay()"
>
  <mat-card class="mat-elevation-z8">
    <mat-card-content>
      <app-notification-overview
        [collapsableSettingsOpen]="collapsableSettingsOpen"
        [notifications]="notifications$ | async"
        [showAll]="showAll"
        (setShowAllEmmiter)="setShowAll($event)"
        (closeOverlayEmmiter)="closeOverlay()"
      />
    </mat-card-content>
  </mat-card>
</ng-template>
