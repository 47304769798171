<app-overview-header [title]="'notification.title' | translate"/>
<mat-card class="mat-elevation-z8 adtrac-card-primary-border">
  <mat-card-content>
    <section class="mt-3">
      <app-notification-overview
        [showNavigation]="false"
        [showAll]="showAll"
        [collapsableSettingsOpen]="true"
        [notifications]="notifications$ | async"
        (setShowAllEmmiter)="setShowAll($event)"
      />
    </section>
  </mat-card-content>
</mat-card>
