<div class="header-wrapper">
  <div class="header-first-section mb-2">
    <a
      class="settings-link"
      (click)="collapsableSettingsOpen = !collapsableSettingsOpen"
      id="notification_settings_link"
    >
      <mat-icon class="mr-2">settings</mat-icon>{{ 'notification.notificationSettings' | translate }}
      <mat-icon class="ml-2 expand_more" [class.expand_less]="collapsableSettingsOpen">expand_more</mat-icon>
    </a>
    @if (!!showNavigation){
    <a class="settings-link" [routerLink]="['/notifications']" id="notification_navigation_link">
      <span translate>notification.title</span> <mat-icon>chevron_right</mat-icon>
    </a>
    }
  </div>
  <section class="collapsable" [class.collapsable-open]="collapsableSettingsOpen">
    <app-notification-subscription
      [showAll]="showAll"
      (setShowAllEmmiter)="setShowAllEmmiter.emit($event)"
    />
  </section>
</div>
<mat-divider/>
<app-notification-list
  [showAll]="showAll"
  [notifications]="notifications"
  (closeOverlay)="closeOverlayEmmiter.emit()"
  (toggleShowAll)="setShowAllEmmiter.emit(true)"
/>
