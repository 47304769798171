import { HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { captureException } from '@sentry/angular';
import { Observable, retry, throwError, timer } from 'rxjs';

@Injectable()
export class BlockedReqeustInterceptor {
  private readonly MAX_NUMBER_OF_RETRY_NO_CONNECTION: number = 5;
  private readonly RETRY_DELAY: number = 0;

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      retry({
        count: this.MAX_NUMBER_OF_RETRY_NO_CONNECTION,
        delay: (error: HttpErrorResponse, retryAttempt: number): Observable<number> => {
          if (error.status != 0) {
            return throwError(() => error);
          }
          if (retryAttempt > this.MAX_NUMBER_OF_RETRY_NO_CONNECTION) {
            captureException({ ...error, reason: 'Max number of retries exceeded' });
            return throwError(() => error);
          }
          return timer(retryAttempt * this.RETRY_DELAY);
        },
      }),
    );
  }
}
