import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, HostBinding, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SetAppOnlineStatus } from '@app/store/app.actions';
import { AppState } from '@app/store/app.state';
import { ThemingService } from '@core/services/theming/theming.service';
import { Store } from '@ngxs/store';
import { Observable, fromEvent, startWith } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';

enum AnimationState {
  OFFLINE = 'offline',
  ONLINE = 'online'
}

const themingService = new ThemingService();
const warn = themingService.warn;
const success = themingService.success;

@Component({
    selector: 'app-offline-banner',
    templateUrl: './offline-banner.component.html',
    styleUrls: ['./offline-banner.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [
        trigger('bannerAnimation', [
            state(AnimationState.ONLINE, style({
                display: 'none'
            })),
            state(AnimationState.OFFLINE, style({
                background: warn
            })),
            transition(`${AnimationState.OFFLINE} => ${AnimationState.ONLINE}`, [
                animate(500, style({ background: success })),
                animate('6000ms cubic-bezier(.67,.14,.85,.99)')
            ]),
            transition(`* => ${AnimationState.OFFLINE}`, [animate('400ms cubic-bezier(.67,.14,.35,.93)')])
        ])
    ],
    standalone: true,
    imports: [MatIconModule, TranslateModule]
})
export class OfflineBannerComponent {
  public onlineStatus: AnimationState = AnimationState.ONLINE;
  public AnimationState = AnimationState;

  @HostBinding('@bannerAnimation') get bannerAnimation() {
    return this.onlineStatus;
  }
  private destroyRef = inject(DestroyRef);
  constructor(public _cdr: ChangeDetectorRef, private _store: Store) {
    this._store
      .select(AppState.appOnline)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(onlineStatus => {
        this.onlineStatus = onlineStatus ? AnimationState.ONLINE : AnimationState.OFFLINE;
        this._cdr.markForCheck();
      });
    this.handleAppConnectivityChanges();
  }

  private handleAppConnectivityChanges(): void {
    const onlineEvent: Observable<Event> = fromEvent(window, 'online');
    const offlineEvent: Observable<Event> = fromEvent(window, 'offline');

    onlineEvent
      .pipe(takeUntilDestroyed(this.destroyRef), startWith(true))
      .subscribe(_ => this._store.dispatch(new SetAppOnlineStatus(true)));

    offlineEvent
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(_ => this._store.dispatch(new SetAppOnlineStatus(false)));
  }
}
