@if (userProfileService.userProfile$ | async; as userProfile) {
<button mat-button [matMenuTriggerFor]="userProfileMenu" id="lyt_btn_user_profile" class="profile-button">
  <mat-icon>account_circle</mat-icon>
  <span class="flex center">
    @if(!mobileQuery) {
      {{ userProfile?.firstName }} {{ userProfile?.lastName }}
    }
    <mat-icon class="arrow-icon ml-1">arrow_drop_down</mat-icon></span
  >
</button>
<mat-menu #userProfileMenu="matMenu" xPosition="before">
  <button mat-menu-item [matMenuTriggerFor]="languageMenu" id="lyt_btn_current_language">
    <mat-icon>language</mat-icon> {{ 'layout.language' | translate }} &nbsp;
    <b class="grey-text"> ({{ languageService.language$ | async }})</b>
  </button>
  <mat-menu #languageMenu="matMenu">
    @for (language of languages; track language) {
    <button mat-menu-item (click)="setLanguage(language)" class="language-selection" id="lyt_btn_{{ language }}">
      {{ language }}
    </button>
    }
  </mat-menu>
  @if (!!modules()?.[BusinessModule.user]){
  <button id="lyt_btn_routto_user_profile" mat-menu-item [routerLink]="['/users/detail/' + userProfile?.id]">
    <mat-icon>manage_accounts</mat-icon> {{ 'user.edit' | translate }}
  </button>
  }

  <button mat-menu-item (click)="changePassword()" id="lyt_btn_change_password">
    <mat-icon>password</mat-icon> {{ 'changePassword' | translate }}
  </button>
  <button mat-menu-item (click)="logout()" id="lyt_btn_logout">
    <mat-icon>exit_to_app</mat-icon> {{ 'logout' | translate }}
  </button>
</mat-menu>
}
