import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { ThemingService } from '@core/services/theming/theming.service';
import { Store } from '@ngxs/store';
import { NotificationMenuComponent } from '@core/components/notification-menu/notification-menu.component';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncPipe } from '@angular/common';
import { NotificationOverviewComponent } from '../notification-overview/notification-overview.component';
import { MatCardModule } from '@angular/material/card';
import { OverviewHeaderComponent } from '../../../shared/components/overview-header/overview-header.component';

@Component({
    selector: 'app-notification-page',
    templateUrl: './notification-page.component.html',
    styleUrls: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [OverviewHeaderComponent, MatCardModule, NotificationOverviewComponent, AsyncPipe, TranslateModule]
})
export class NotificationPageComponent extends NotificationMenuComponent {
  constructor(_themingService: ThemingService, media: MediaMatcher, _store: Store, _cdr: ChangeDetectorRef) {
    super(_themingService, media, _store, _cdr);
    this.notifications$ = this.unreadNotifications$;
    _cdr.markForCheck();
  }
}
