@if (subscriptions) {
  <p>{{ 'notification.possibleSubscriptions' | translate }}:</p>
  <div class="ml-2">
    @for (subscription of subscriptions; track subscription.id) {
      <mat-slide-toggle
        color="primary"
        class="mr-3"
        [id]="subscription.eventType"
        [checked]="subscription.status == SubscriptionStatus.active"
        (change)="toggleSubscription(subscription.id)"
        >{{ 'notification.subscriptions.' + subscription.eventType | translate }}</mat-slide-toggle
      >
    }
    <mat-slide-toggle
      color="primary"
      class="mr-3"
      id="unread-notifications"
      [checked]="showAll"
      (change)="setShowAllEmmiter.emit()"
      >{{ 'notification.showAll' | translate }}</mat-slide-toggle
    >
  </div>
} @else {
  <section class="mt-3">
    <app-skeleton-loader [theme]="{ width: '60%', height: '1rem' }" />
    <app-skeleton-loader [theme]="{ width: '90%', height: '1rem', display: 'block' }" />
  </section>
}
