import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { init } from '@sentry/angular';
import 'hammerjs';
import 'chartjs-plugin-zoom';
import * as Sentry from '@sentry/browser';
let tracingOrigins = [];

if (environment.production) {
  enableProdMode();
  tracingOrigins = ['*', environment.backendUrl];
}

init({
  dsn: 'https://89fbebee83c04432befa7183463507b2@o954585.ingest.sentry.io/5903747',
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration({ useCompression: true })],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: environment.production ? 0.005 : 1,
  replaysOnErrorSampleRate: 0.01,
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
