import { Params, RouterStateSnapshot } from '@angular/router';
import { RouterStateSerializer } from '@ngxs/router-plugin';

export interface RouterStateModel {
  url: string;
  params: Params;
  queryParams: Params;
  data: any;
}

export class AdtracRouterStateSerializer implements RouterStateSerializer<RouterStateModel> {
  serialize(routerState: RouterStateSnapshot): RouterStateModel {
    const {
      url,
      root: { queryParams }
    } = routerState;

    let params;
    let data;

    let { root: route } = routerState;
    while (route.firstChild) {
      route = route.firstChild;
      params = { ...params, ...route.params };
      data = { ...data, ...route.data };
    }

    if (route.params.title) {
      data.title = route.params.title;
    }

    return { url, params, queryParams, data };
  }
}
