<div
  matRipple
  class="progress-btn active"
  data-progress-style="fill-back"
  (click)="startApplicationUpdate()"
  [matTooltip]="('updateInfo' | translate) + ' ' + (timeRemaining | date: 'mm:ss')"
  matTooltipPosition="below"
>
  <mat-icon class="update_button">refresh</mat-icon>
  <div class="btn">{{ 'update' | translate }}</div>

  <div matRipple class="progress"></div>

  <small class="info-container" [class.display-none]="!infoExpanded"
    >{{ 'updateInfo' | translate }}
    {{ timeRemaining | date: 'mm:ss' }}
  </small>
  <button mat-icon-button class="info-button" (click)="handleInfoClick($event)">
    <mat-icon>{{ infoExpanded ? 'arrow_back_ios_new' : 'info' }}</mat-icon>
  </button>
</div>
