<mat-toolbar color="accent" class="sticky-header no-print">
  @if(mobileQuery.matches){
  <button mat-icon-button (click)="sidenav.toggle()" id="lyt_btn_side_nav">
    <mat-icon>menu</mat-icon></button
  >}
  <div [inlineSVG]="environment.assetsUrl + 'logo-white.svg'" class="logo" alt="Adtrac"></div>
  <div>
    <button mat-button [matMenuTriggerFor]="languageMenu" class="language-selection" id="lyt_btn_current_language">
      {{ language }}<mat-icon>arrow_drop_down</mat-icon>
    </button>
    <mat-menu #languageMenu="matMenu">
      @for (language of languages; track language) {
      <button mat-menu-item (click)="setLanguage(language)" class="language-selection" id="lyt_btn_{{ language }}">
        {{ language }}
      </button>
      }
    </mat-menu>
  </div>
</mat-toolbar>
<ng-progress [spinner]="false" [color]="primaryColor" [meteor]="false" [thick]="true" [trickleSpeed]="100"/>

<mat-sidenav-container autosize>
  <mat-sidenav
    #sidenav
    id="lyt_nav_sidenav"
    [mode]="mobileQuery.matches ? 'over' : 'side'"
    [fixedInViewport]="mobileQuery.matches"
    [opened]="!mobileQuery.matches"
    [disableClose]="!mobileQuery.matches"
    class="no-print"
  >
    <mat-nav-list>
      <ng-container>
        <a mat-list-item routerLinkActive="active" id="lyt_link_routto_cmpgn">
          <mat-icon>view_headline</mat-icon>
          <span>{{ 'campaign.campaigns' | translate }}</span>
        </a>

        @if(router.url.includes('campaigns/detail')){ @if(router.url.includes('media')){
        <a id="lyt_link_routto_media" mat-list-item routerLinkActive="active-sub" class="submenu">
          <mat-icon>movie</mat-icon>
          <span>{{ 'campaign.detail.media' | translate }}</span>
        </a>
        } @if(router.url.includes('playouts')){
        <a id="lyt_link_routto_playouts" mat-list-item routerLinkActive="active-sub" class="submenu">
          <mat-icon>assessment</mat-icon>
          <span>{{ 'campaign.detail.playouts' | translate }}</span>
        </a>
        } }
      </ng-container>
    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav-content [class.expanded]="!mobileQuery.matches">
    <div class="view-container p-4">
      <app-breadcrumbs/>
      <router-outlet/>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
