import { Injectable } from '@angular/core';
import { SwPush } from '@angular/service-worker';
import { FetchNotifications, FetchSubscriptions } from '@app/store/app.actions';
import { Store } from '@ngxs/store';

const PUBLIC_KEY = 'BF0n6JtSjUX3T5klOnDGSzeXM0xC_n23ksZajgn0jlRN-gSqHEHmu1QqrOHF1YuRysotaUIUu65lvC7rSoZS2Tg';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(private _swPush: SwPush, private _store: Store) {
    this._store.dispatch(new FetchSubscriptions());
    this._store.dispatch(new FetchNotifications());

    // TODO uncomment this once we have a full integrated push notifcation mechanism
    // this.getSubscription();

    // this._swPush.messages.subscribe(msg => {
    //   console.log('NEW MESSAGE : ', JSON.stringify(msg));
    // });
  }

  getSubscription() {
    if (Notification.permission === 'default') {
      Notification.requestPermission()
        .then(() => {
          this.requestSubscription();
        })
        .catch(() => {
          // show permission denied error
        });
    } else if (Notification.permission === 'denied') {
      // show permission is denied, please allow it error
    } else {
      this.requestSubscription();
    }
  }

  async requestSubscription() {
    try {
      const key = PUBLIC_KEY;
      const sub = await this._swPush.requestSubscription({
        serverPublicKey: key
      });
      // console.log('@@@ ', JSON.stringify(sub));
      // console.log('#### ', sub);
    } catch (e) {
      // console.log(e);
    }
  }
}
